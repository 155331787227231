import React from "react";
import pageStyle from "../../assets/css/page.module.css";
import workStyle from "../../assets/css/work.module.css";
import style from "../../assets/css/main.module.css";
import Menu from "../../components/menu";
import Footer from "../../components/footer";
import HeroPage from "../../components/hero.page";
import Hamburger from "../../components/hamburger";


import imgHeader from "../../assets/img/realisations/fanny_0.jpg";
import { Link } from "gatsby";
import SEO from "../../components/seo";

export default () => (
  <div id="top">
    <Menu />
    <SEO
    title="Fanny Wautelet | Agence digitale | MDS Digital Agency"
    description="Elaboration d’une stratégie digitale complète pour Fanny Wautelet : création de site internet, logo, cartes de visites, etc."
    keywords="Fanny Wautelet"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgHeader} />
    <div className={workStyle.info}>

      <div className={workStyle.info_cell}>
        <h3>Services</h3>
        <span className={workStyle.badge}><Link to="/services/website/">Création de site web</Link></span>
        <span className={workStyle.badge}><Link to="/services/referencement/">Référencement</Link></span>
        <span className={workStyle.badge}><Link to="/services/graphic-design/">Graphic design</Link></span>
        <span className={workStyle.badge}><Link to="/services/conseils/">Conseils</Link></span>


      </div>
      <div className={workStyle.info_cell}>
      <h3>Secteur</h3>
        <p>Santé</p>
        <h3>Localisation</h3>
        <p>Mons</p>
      </div>

      <div className={workStyle.info_cell}>
        <h3>Lien </h3>
        <p>
          <a href="http://www.fannywautelet.be/" className={workStyle.external_link} target="_blank" rel="noopener noreferrer">fannywautelet.be</a>
        </p>
      </div>
    </div>

    <article className={pageStyle.article}>
      <h2>Fanny Wautelet</h2>
      <p>
      Spécialiste en neuropsychologie, Fanny Wautelet souhaitait une prise en charge complète pour son projet digital, de la création du site à la réalisation d’un branding à la fois authentique et original.
      </p>

      <p>
      Nous avons donc créé un site à l’image de Fanny Wautelet: dynamique et fiable.
      </p>


      <p>
      Le site internet a été entièrement optimisé pour le référencement naturel (SEO). Ceci a permis de placer le site Fanny Wautelet en tête des résultats de recherches Google sur base d’une série de mots clés pertinents.
            </p>
      <p>
      Au delà du site web, MDS a également développé l’identité visuelle de Fanny Wautelet, créé son logo et produit ses cartes de visite.
            </p>

      <div className={pageStyle.cta}>
      <Link to="/contact/">Contactez-nous pour en savoir plus</Link>
      </div>
    </article>
    <Footer />
  </div>
);
